<template>
  <base-layout>
    <ion-content id="wcpt-exhibitor-detail" class="has-header has-subheader">
      <template v-if="exhibitor">
      </template>

      <div class="exhibitor-title">
        <h1 :style="{color: exhibitor.fax ? exhibitor.fax : '#000'}">{{ exhibitor.name }}</h1>
      </div>
      <div class="mobile-activated">
        <div class="exhibitor-profile" style="max-width:100vw">
          <div class="logo" style="text-align: center">
            <img class="company-logo" :src="'https://fileserver-188ab.kxcdn.com/file/'+ exhibitor.logo.split('/')[4]"
                 v-if="exhibitor.logo" style="max-width: 300px; object-fit:contain">
          </div>
          <div class="exhibitor-buttons" style="max-width:100%">
            <div class="exhibitor-btn">
              <a style="text-decoration:none;color:#fff" :style="{'background-color': exhibitor.fax}"
                 :href="exhibitor.homepage" target="_blank" rel="noopener"><i class="icon ion-ios-chatbubble"></i>&nbsp;Website</a>
            </div>


            <template v-if="details && details.virtualbooth && details.virtualbooth.length > 0">
              <div class="exhibitor-btn" v-for="booth in details.virtualbooth" :key="booth.id">
                <a style="text-decoration:none;color:#fff;font-size:0.9rem" v-if="isExternalLink(booth.media_file)" :href="booth.media_file" target="_blank"
                   @click="trackWebsite(booth.media_file);trackUserEvent(booth);">{{ booth.description }}</a>
                <a style="text-decoration:none;color:#fff;font-size:0.9rem" v-if="!isExternalLink(booth.media_file)"
                   @click="trackWebsite(booth.media_file);trackUserEvent(booth);openLink(booth.media_file)">{{ booth.description }}</a>
              </div>

            </template>

            <FavoriteButton class="exhibitor-btn" :fav-id="favId" v-if="exhibitor.id"></FavoriteButton>

            <!-- v-if exhibitor enabled-->
            <!--div class="exhibitor-btn" style="text-decoration:none;color:#fff; line-height:1.4rem" @click="showModal()"
                 :style="{'background-color': exhibitor.fax}">
              1 to 1 Meeting
            </div-->


          </div>
          <div style="display:flex; justify-content:flex-start; margin:0 30px;">
            <div class="contact-area">
              <div class="contact" style="display: flex;flex-flow: column;">
                <a class="contact-item" style="white-space:normal" :href="'mailto:' + exhibitor.email" v-if="exhibitor.email" target="_blank">
                  <i class="icon ion-ios-email-outline" style="margin-right:5px"></i>
                  <span style="display:inline-block;white-space:normal"> {{ exhibitor.email }}</span>
                </a>
                <div class="contact-item" v-if="exhibitor.phone">
                  <i class="icon ion-ios-telephone"></i>
                  <span>{{ exhibitor.phone }}</span>
                </div>

              </div>
              <div class="address">
                <div class="contact-item">{{ exhibitor.street }}</div>
                <div class="contact-item">{{ exhibitor.city }}<span v-if="exhibitor.city">, </span>{{
                    exhibitor.zip_code
                  }}
                </div>
                <div class="contact-item">{{ exhibitor.country }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="exhibitor-description">
        <div class="about" v-html="exhibitor.description"></div>
      </div>


        <div class="exhibitor-profile ">
          <div class="mobile-deactivated">
          <div class="logo" style="text-align: center">
            <img class="company-logo" :src="'https://fileserver-188ab.kxcdn.com/file/'+ exhibitor.logo.split('/')[4]"
                 v-if="exhibitor.logo" style="height: 80px; object-fit:contain">
          </div>
          <div class="exhibitor-buttons">
            <div class="exhibitor-btn">
              <a style="text-decoration:none;color:#fff" :style="{'background-color': exhibitor.fax}"
                 :href="exhibitor.homepage" target="_blank" ><i class="icon ion-ios-chatbubble"></i>&nbsp;Website</a>
            </div>
            <!--div class="exhibitor-btn">
              <a style="text-decoration:none;color:#fff" :style="{'background-color': exhibitor.fax}"
                 :href="'mailto:' + exhibitor.email" target="_blank"><i class="icon ion-ios-chatbubble"></i>&nbsp;Contact</a>
            </div-->
            <!--div class="exhibitor-btn">
             <a style="text-decoration:none;color:#fff" :style="{'background-color': exhibitor.fax}"
                @click="startChat()" target="_blank"><i class="icon ion-ios-chatbubble"></i>&nbsp;Chat</a>
           </div-->

            <template v-if="details && details.virtualbooth && details.virtualbooth.length > 0">
              <div class="exhibitor-btn" v-for="booth in details.virtualbooth" :key="booth.id">
                <a style="text-decoration:none;color:#fff;font-size:0.9rem" v-if="isExternalLink(booth.media_file)" :href="booth.media_file" target="_blank"
                   @click="trackWebsite(booth.media_file);trackUserEvent(booth);">{{ booth.description }}</a>
                <a style="text-decoration:none;color:#fff;font-size:0.9rem" v-if="!isExternalLink(booth.media_file)"
                   @click="trackWebsite(booth.media_file);trackUserEvent(booth);openLink(booth.media_file)">{{ booth.description }}</a>
              </div>

            </template>

            <FavoriteButton class="exhibitor-btn" :fav-id="favId" v-if="exhibitor.id"></FavoriteButton>

            <!-- v-if exhibitor enabled-->
            <!--div class="exhibitor-btn" style="text-decoration:none;color:#fff; line-height:1.4rem" @click="showModal()"
                 :style="{'background-color': exhibitor.fax}">
              1 to 1 Meeting
            </div-->


          </div>
          <div style="display:flex; justify-content:center">
            <div class="contact-area">
              <div class="contact" style="display: flex;flex-flow: column;"><br>
                <a class="contact-item" style="white-space:normal" :href="'mailto:' + exhibitor.email" v-if="exhibitor.email" target="_blank">
                  <i class="icon ion-ios-email-outline" style="margin-right:5px"></i>
                  <span style="display:inline-block;white-space:normal"> {{ exhibitor.email }}</span>
                </a>
                <br>
                <div class="contact-item" v-if="exhibitor.phone">
                  <i class="icon ion-ios-telephone"></i>
                  <span>{{ exhibitor.phone }}</span>
                </div>

              </div>
              <div class="address">
                <div class="contact-item">{{ exhibitor.street }}</div>
                <div class="contact-item">{{ exhibitor.city }}<span v-if="exhibitor.city">, </span>{{
                    exhibitor.zip_code
                  }}
                </div>
                <div class="contact-item">{{ exhibitor.country }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <hr style="margin-top:50px" v-if="showResourceSection"><!-- v if details -->
      <div class="web-exhibitor-divider" v-if="showResourceSection" style="margin-left:10px"><strong>Resource centre</strong>
      </div>

      <div class="exhibitor-resources" v-if="details" style="margin-left:10px">
        <div class="video-container" v-if="details.videos && details.videos.length > 0">
          <div class="video-slot" v-for="video in details.videos" :key="video.id">
            <div class="stream">
              <iframe :src="video.media_file"
                      style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
                      allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div>{{ video.description }}</div>
          </div>
        </div>


        <div class="file-container" v-if="details.files &&  details.files.length > 0">
          <div class="files">
            <div class="file" v-for="file in details.files" :key="file.id">
              <div class="thumbnail" @click="handleFile(file,true)">
                <div class="thumb-content">
                  <img :src="file.icon" style="max-height:230px; object-fit: contain" v-if="file.icon">
                  <img :src="file.media_file + '/face/220/305'" style=" object-fit: cover"
                       v-if="!file.icon">
                  <!--div class="text">{{file.description}}</div-->
                </div>

              </div>
              <div class="thumb-action">
                <div class="text" style="height:30px; line-height:1rem">{{ file.description }}</div>
                <br>


                <div class="dl-btn" v-if="!file.readonly"><a :href="file.media_file" target="_blank" @click="trackUserEvent(file, 'download')">
                  <ion-icon name="download-outline"></ion-icon>
                </a></div>
              </div>

            </div>

          </div>
        </div>


        <div v-if="details.links && details.links.length > 0" style="margin-left: 20px;">
          <h3 >Additional Links</h3>
          <div class="link-container" style="">
            <div style="margin:0 10px 5px 0" class="link-btn" v-for="link in details.links" :key="link.id"
                 @click="trackWebsite(link.media_file); trackUserEvent(link)">
              <a style="white-space: normal;margin-right: 20px;" :href="link.media_file" target="_blank"> {{ link.description || link.media_file }}</a>
            </div>
          </div>
        </div>
      </div>
    </ion-content>


  </base-layout>
</template>


<script>
import {IonContent, modalController} from "@ionic/vue";
import ExhibitorRequestModal from "../../components/Exhibitions/ExhibitorRequestModal.vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import PdfModal from "@/views/Industry/PdfModal";
import {addIcons} from "ionicons";
import {downloadOutline} from "ionicons/icons";
import FavoriteButton from "@/components/favorite/FavoriteButton";
import router from "@/router";

addIcons({"download-outline": downloadOutline});

export default defineComponent({
  name: "ExhibitorDetail",
  data() {
    return {
      exhibitor: {},
      medias: [],
      openModal: false,
      details: {},
      showResourceSection: false,
      favId: null

    };
  },
  components: {
    IonContent,
    FavoriteButton
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('chat', ['currentRoom']),
  },
  methods: {
    ...mapActions("exhibitors", [
      "getExhibitor",
      "getMedias",
      "getDetails"
    ]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions('chat', ['forceUserCreate', 'enterRoom']),

    async showModal() {
      const modal = await modalController
          .create({
            component: ExhibitorRequestModal,
            cssClass: 'my-custom-class',
            componentProps: {
              title: 'Request a meeting appointment to ' + this.exhibitor.name,
              content: this.exhibitor
            },
          })
      return modal.present();
    },
    trackWebsite(website) {
      this.trackWithLabel('Website viewed:' + website);


    },
    trackUserEvent(file, action){
      let trackData = {
        user: this.user,
        item : file,
        event: action || 'viewed',
        type:file.type,
        itemId: file.id,
      }
      this.trackEvent(trackData);
    },
    openLink(link) {
      console.log(link)

      if (new RegExp("^(http|https)://").test(link)) {
        window.open(link, '_blank', 'location=no');
      } else {
        router.push(link);
      }
    },
    isExternalLink(link){
      return new RegExp("^(http|https)://").test(link)
    },
    getPDFLink(link, rewrite) {
      let reslink = link;
      if (rewrite) {
        reslink = 'https://fileserver.s3-de-central.profitbricks.com/' + link.split('/')[4];//TEST
      }
      return reslink;
    },

    async handleFile(file) {
      let link = this.getPDFLink(file.media_file, true);
      let viewerLink = 'https://pdf.abstractserver.com/viewer/#/' + link;
      console.log(viewerLink)

      this.modal = await modalController
          .create({
            component: PdfModal,
            cssClass: 'pdf-modal',
            componentProps: {
              title: file.description,
              link: viewerLink
            },
          })


      let trackData = {
        user: this.user,
        item : file,
        event: 'viewed',
        type:'document',
        itemId: file.id,
      }
      this.trackEvent(trackData);
      return this.modal.present();
    },

    async startChat() {
      let _exhibUser = { id: this.exhibitor.auth_user,
        firstname: 'Industry:',
        lastname: this.exhibitor.name,
        avatar_id:  '',
        additional_content:{'usertype': 'industry'}
      }
      await this.forceUserCreate(_exhibUser)
      await this.enterRoom([this.user.id, this.exhibitor.auth_user]);
      this.$router.push('/app/chats/' + this.currentRoom)
    }
  },
  async created() {
  }
  ,
  watch: {
    '$route':
        {
          immediate: true,
          async handler(route) {
            if (route.name == 'Exhibitors Detail') {
              this.exhibitor = await this.getExhibitor(route.params.id);
              this.favId = 'exhibitors/' + this.exhibitor.id;
              let trackData = {
                user: this.user,
                item : this.exhibitor,
                event: 'enter',
                type:'exhibitor',
                itemId: this.exhibitor.id,
              }
              this.trackEvent(trackData);
              this.details = await this.getDetails(this.exhibitor.id);
              console.log(this.details)
              console.log(this.details.videos)
              this.showResourceSection = this.details && (this.details.videos.length + this.details.links.length + this.details.files.length) > 0;
              this.trackWithLabel(this.exhibitor.name);
              this.uploadTrackingEvents();
            }
          }
        },
    updateFinished: async function (newVal) {
      if (newVal) {
        this.exhibitor = await this.getExhibitor(this.$route.params.id);
        this.favId = 'exhibitors/' + this.exhibitor.id;
        this.details = await this.getDetails(this.exhibitor.id);
        console.log(this.details)
        console.log(this.details.videos)
        this.showResourceSection = this.details && (this.details.videos.length + this.details.links.length + this.details.files.length) > 0;

      }
    },
  }

})
;
</script>
<style lang="scss">

@media (min-width: 768px) {
  .pdf-modal {
    --width: 80%;
    --height: 80%;
  }
}

</style>
