<template>

    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
                <ion-button @click="closeModal()"> <ion-icon class="request-icon" :icon="closeOutline"></ion-icon></ion-button>
              </ion-buttons>
    </ion-toolbar>
  <ion-content class="ion-padding" v-if="!consultantsByExhibitor.length>0"> <!-- remove ! for live-->
    <!--div ng-if="user"-->
    <div class="list">
      <ion-item>
      <ion-label>
        <ion-input type="text" placeholder="Subject  *"  @IonChange="inputData.inputSubject=$event.target.value"></ion-input> <!--NO SE HACER EL V-MODEL-->
      </ion-label>
       </ion-item>
      <ion-item>
      <ion-label>
         <ion-input type="text" placeholder="Description  *" @IonChange="inputData.inputDescription=$event.target.value"></ion-input> <!--NO SE HACER EL V-MODEL-->
      </ion-label>
       </ion-item>
    </div>
   

  <ion-item>
        <ion-label>  Select a Consultant: </ion-label>
        <ion-select placeholder="Select" v-model="selectedConsultant" @ionChange="changedConsultant()" > 
          <ion-select-option v-for="consultant in consultantsByExhibitor" :key="consultant.id" :value="consultant" >{{consultant.firstname}} {{consultant.lastname}} </ion-select-option>
        </ion-select>
      </ion-item>
     

    <div class="list"  v-if="consultantSelected == true" >
       <ion-item>
          <ion-label>Select a day:</ion-label>
          <ion-datetime  presentation="date" v-model="selectedDate" @ionChange="createSessionsArray(true)" placeholder="Select Date" :min="minDay" :max="maxDay"></ion-datetime>
        </ion-item>
    </div>
    
   <ion-item v-if="daySelected == true">
    <div v-if="daySelected == true" class="item"> 
      <div style="display:inline-block; padding-top: 15px; margin-right:70px">
        <ion-label>  Select a Session: </ion-label>
      </div>
      <div style="display:inline-block">
        <div class="appointment-wrapper" style="padding-bottom: 15px;">
            
          <div class="appointment-item" v-for="(session) in sessionsArray" :key="session" @click="selectSession(session)" :disabled="checkDisabled(session)" :style="prepareSessionItemStyle(session)"> 
            <span v-if="session.available!=false" > {{getFormat(session.sessionStart) }} </span> 
            <span v-if="session.available==false" style="color:lightgrey"> {{getFormat(session.sessionStart) }} </span>
          </div>
        </div>
      </div>
    </div>
    </ion-item>


    <!--/div-->
    <div class="col" v-if="sessionSelected == true">
       <button style="margin-top: 20px; min-width: 100% !important; background: #3d5a59; color:white" class="create-consultant-button requests-accept-button" @click="submitRequest()">
                  Request Appointment
        </button>
    </div>
  
  </ion-content>
  <ion-content v-else>
    <div style="margin: 30px; color: red;">
      This Exhibitor has no consultants, thus he cannot receive meeting requests.
    </div>
  </ion-content>

</template>


<script>
import { IonContent, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonDatetime, IonInput, modalController } from '@ionic/vue';
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment'
import {closeOutline} from 'ionicons/icons';

const config = require('@/config');

export default {
  name: "ExhibitorRequestModal",
  props: ['title','content'],
  data() {
    return {
    days: {},
    minDay:null,
    maxDay:null,
    inputData: {},
    exhibitor: this.content,
    conference: null,
    selectedConsultant: null,
    consultantSelected: false,
    daySelected: false,
    sessionSelected: false,
    selectedSession: null,
    appointmentList: null,
    selectedDate: null,
    consultantAppointmentList:null,
    sessionsArray: null,
    sessionRequest: null,
    newRequest:null,
  
    }
  },

  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };

    return {closeModal, closeOutline}
  },

   async created(){
      if(localStorage.getItem('x-api-key') != ''){       
         let userRequest = await this.$store.dispatch('auth/GET_USERDATA');
         this.user = userRequest.data
        console.log("user")
        console.log(this.user.id)
                console.log(this.user.emailBusiness)

      }
        this.days = await this.getDays();
        this.fetchConference(); //GET CONFERENCE POR ALGUN MOTIVO SI VA PERO ESTA UNDEFINED EN LA SIGUIENTE FUNCION
        this.fetchAppointments(); //cosa nueva
        this.fetchConsultantsByExhibitor();
        this.setDayBracket();
  },

  computed:{
     ...mapGetters('consultant',['consultantsByExhibitor']),
  },
  
  methods: {
    ...mapActions('sessions', ['getDays']),
   ...mapActions('consultant',['getConsultantsByExhibitor']),
      setDayBracket(){
        let daysArray=[];
        for(let d in this.days){
          daysArray.push(d);
        }

        this.minDay=daysArray[0] //I Should add some kind of control here to check if the values are actually ordered in the original getDays
        this.maxDay=daysArray[daysArray.length-1]
      },
      prepareSessionItemStyle(session){
        let styles = {
           'border-color': session.available ? '#000' : 'lightgrey',
          'color':'#000',
          'pointer-events' : session.available != false ? 'visible' : 'none',
        };
      
        if(this.selectedSession && this.selectedSession.sessionStart == session.sessionStart ){
          styles['border-color'] = '#3d5a59';
          styles['color'] = '#3d5a59'
        }
        return styles;
      },

      checkDisabled(session){
        return session.available
      },
      getFormat(time){
        let formatted = moment(time).format('HH:mm')

        return formatted;
      },
        async getCurrentDate(){
            this.selectedDate = {};
            this.selectedDate = new Date();
          return this.selectedDate;
        },

        async fetchConference(){
        this.conference = (await this.axios.get("https://core.api.documedias.systems/api/eventyears/"+ config.conference)).data; 
        },

       async fetchAppointments() {
         console.log("fetchAppoinments")
        let obj = {"ExhibitorId": this.exhibitor.id, "ConferenceId": (await this.axios.get("https://core.api.documedias.systems/api/eventyears/"+config.conference)).data} 
        this.appointmentList = (await this.axios.post("https://dev.appointment.documedias.systems/api/appointments/getbyexhibitor", obj)).data; 
       },

       async fetchConsultantsByExhibitor() {

       await this.getConsultantsByExhibitor(this.exhibitor.id)
        console.log(this.consultantsByExhibitor)
       },

       async changedConsultant(){
        this.consultantSelected = true;
        this.createSessionsArray(false);
       },

        async createSessionsArray(notReset){
        this.sessionSelected = false;
        this.selectedSession = null;
        
        this.consultantAppointmentList = (await this.axios.post("https://dev.appointment.documedias.systems/api/appointments/getacceptedbyconsultantid/"+ this.selectedConsultant.id)).data; 
        this.sessionsArray = [];
        let start =  moment(this.selectedDate).format('yyyy-MM-DD') 
        console.log(start)
       
       

        let startDate = moment(start+ 'T' + this.selectedConsultant.available_from + ':00')
        let endDate = moment(start+ 'T' + this.selectedConsultant.available_until + ':00')
        let tmpDate = startDate;
        this.sessionsArray.push({sessionStart: startDate.toISOString(), consultant: this.selectedConsultant, requester_user_id: this.user.id });
        while(tmpDate.isBefore(endDate)){
          let sessionData= {};
          let newDate = tmpDate.add(this.selectedConsultant.hour_duration,'hours').add(this.selectedConsultant.minute_duration,'minutes').toISOString();
          sessionData.sessionStart = newDate;
          sessionData.consultant = this.selectedConsultant
          sessionData.requester_user_id = this.user.id; 
          this.sessionsArray.push(sessionData);
          tmpDate = moment(sessionData.sessionStart);
        }     

        this.sessionsArray.pop() 
        for(let i = 0; i < this.sessionsArray.length; i++){

          let tmpStart = moment(this.sessionsArray[i].sessionStart).toISOString();
          let tmpEnd = moment(this.sessionsArray[i].sessionStart).add(this.selectedConsultant.hour_duration,'hours').add(this.selectedConsultant.minute_duration,'minutes').toISOString();
          
          if(moment(tmpStart).isBefore()){
            this.sessionsArray[i].available=false;
          }

          for(let x = 0; x < this.consultantAppointmentList.length; x++){
            let tmpExistingAppStart = moment(this.consultantAppointmentList[x].start_at).toISOString();
            let tmpExistingAppEnd = moment(this.consultantAppointmentList[x].start_at).add(this.selectedConsultant.hour_duration,'hours').add(this.selectedConsultant.minute_duration,'minutes').toISOString();

            tmpExistingAppStart = moment(tmpExistingAppStart).subtract(2,'hours')
            tmpExistingAppEnd = moment(tmpExistingAppStart).subtract(2,'hours')

            if(moment(tmpExistingAppStart).isSame(moment(tmpStart), 'minute')){
              this.sessionsArray[i].available=false;
            }
            if(moment(tmpExistingAppStart).isBetween(moment(tmpStart), moment(tmpEnd), 'minute')){
              this.sessionsArray[i].available=false;
            }
            if(moment(tmpExistingAppEnd).isBetween(moment(tmpStart), moment(tmpEnd), 'minute')){
              this.sessionsArray[i].available=false;
            }
          }
        }
        if(notReset)
        this.daySelected = true;
       },

        selectSession(session){
          
            console.log(session)

            this.selectedSession = session;
            this.sessionRequest = session;
            this.newRequest = {};
            this.newRequest.consultant_id =this.selectedConsultant.id; 
            this.newRequest.requester_user_email = this.user.emailBusiness
            this.newRequest.requester_user_id = this.user.id
            this.newRequest.description = this.inputData.inputDescription;
            this.newRequest.location = this.conference.db_name;
            this.newRequest.subject =  this.inputData.inputSubject;
            this.newRequest.start_at =  moment(this.sessionRequest.sessionStart).add(2,'hours').toISOString();
            this.newRequest.end_at = moment(this.newRequest.start_at).add(this.selectedConsultant.hour_duration,'hours').add(this.selectedConsultant.minute_duration,'minutes').toISOString();
            this.newRequest.requested = true;
            this.newRequest.accepted = false;
            this.newRequest.conference_id = this.conference.id ;
            this.newRequest.rejected = false;

            this.sessionSelected = true;

       },

        async submitRequest(){
        this.newRequest.description = this.inputData.inputDescription;
        this.newRequest.subject = this.inputData.inputSubject;

         if( this.inputData.inputSubject != null && this.inputData.inputDescription != null && this.inputData.inputSubject != " " && this.inputData.inputDescription != " "){
        await this.axios.post("https://dev.appointment.documedias.systems/api/appointments/", this.newRequest)

        this.newRequest = {};
        this.consultantSelected = false;
        this.consultantSelected = false;
        this.daySelected = false;
        this.sessionSelected = false;
        this.selectedSession = null;
        this.sessionRequest = {}
        this.inputData.inputDescription = null;
        this.inputData.inputSubject = null;
        alert("Your request has been sent")

        } else {
          alert("Please introduce a valid subject and description")
          this.selectedSession = null;
          this.sessionSelected = false;
        }
       }

  },

  watch: {
    'selectedConsultant': function(newval, oldval){
      console.log('New', newval);
      console.log('Old', oldval);
    }
  },

  components: { IonContent, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonDatetime,IonInput}
};
</script>